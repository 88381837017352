<template>
  <div class="row">
    <div class="flex md12">
      <va-card>
        <va-card-content>
          <va-select
            class="mb-4"
            label="Destination"
            :options="locationOptions"
            :track-by="(option) => option.id"
            :text-by="customFilter"
            :value-by="(option) => option.id"
            v-model="selectedLocation"
            placeholder="Destination"
            @update-search="updateLocationOptions"
            allowCreate
            multiple
            :loading="loadingLocations"
          />
          <div class="v-row">
            <div class="flex md4">
              <va-button @click="generateQuestions" :loading="generateQuestionsLoader">Generate Q&A</va-button>
            </div>
          </div>
          <div class="row">
            <div class="flex md4">
              <va-button @click="generateMonthlyDescriptions" :loading="generateMonthlyDescriptionsLoader">
                Generate Monthly Descriptions
              </va-button>
              <va-button @click="generateMonthlyQuestions" :loading="generateMonthlyQuestionsLoader">
                Generate Monthly Questions
              </va-button>
            </div>
          </div>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Novembre",
  "Décembre"]
import {useGlobalConfig} from "vuestic-ui";

export default {
  name: "questions",
  data () {
    return {
      locationOptions: [],
      selectedLocation: null,
      loadingLocations: false,
      generateQuestionsLoader: false,
      generateMonthlyDescriptionsLoader: false,
      generateMonthlyQuestionsLoader: false,
    }
  },
  computed: {
    crawlerUrl() {
      return useGlobalConfig().getGlobalConfig().crawlerUrl
    },
  },
  methods: {
    customFilter (option, query) {
      return option.name
    },
    async updateLocationOptions (search) {
      if (search.length > 2) {
        try {
          this.loadingLocations = true
          const response = await this.axios.get(`/api/locations?search=${search}`)
          this.locationOptions = response.data
        } finally {
          this.loadingLocations = false
        }
      }
    },
    async generateQuestions() {
      this.generateQuestionsLoader = true
      for (const location of this.selectedLocation) {
        try {
          const questionsResponse = await this.axios.post(`${this.crawlerUrl}/generate_questions`, {
            destinations: location.name,
          })
          const response = await this.axios.post(`/api/admin/questions`, {
            questions: questionsResponse.data,
            location
          })
          console.log(response.data)
        } catch (e) {
          console.log(e)
        }
      }
      this.generateQuestionsLoader = false
    },
    async generateMonthlyDescriptions() {
      this.generateMonthlyDescriptionsLoader = true
      for (const month of months) {
        try {
          const descriptionResponse = await this.axios.post(`${this.crawlerUrl}/generate_descriptions`, {
            month,
            destinations: this.selectedLocation.map(location => location.name),
          })
          const response = await this.axios.post(`/api/admin/descriptions`, {
            month,
            descriptions: descriptionResponse.data,
          })
          console.log(response.data)
        } catch (e) {
          console.log(e)
        }
      }
      this.generateMonthlyDescriptionsLoader = false
    },
    async generateMonthlyQuestions() {
      this.generateMonthlyQuestionsLoader = true
      for (const month of months) {
        try {
          const questionsResponse = await this.axios.post(`${this.crawlerUrl}/generate_questions`, {
            destinations: this.selectedLocation.map(location => location.name),
            month,
          })
          const response = await this.axios.post(`/api/admin/questions`, {
            questions: questionsResponse.data,
            month,
          })
          console.log(response.data)
        } catch (e) {
          console.log(e)
        }
      }
      this.generateMonthlyQuestionsLoader = false
    },
  }
}
</script>

<style scoped>

</style>
